$primary-main: #0DB14B;
$primary-half-alpha: #0a813750;
$secondary-main: #7F0166;
$secondary-half-alpha: #7F016650;

.slider-tray {
  &__animation {
    transition: transform .3s ease-in-out;
  }
}

.slide {
  position: relative;
  margin: auto 0;
}

.btn {
  padding: 8px;
  border-radius: 50%;
  color:#ffffff;
  background-color: #00000075;
  border: none;
  &-middle {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  &-back {
    left: 36px;
  }
  &-next {
    right: -12px;
  }
}

.dot {
  &-container {
    display: flex;
    justify-content: center;
    column-gap: 8px;
    padding: 16px 0;
  }

  width: 24px;
  height: 8px;
  border: none;
  border-radius: 4px;
  
  &__farmer {
    background-color: $secondary-half-alpha;
    &-selected {
      background-color: $secondary-main;
    }
  }
  &__driver {
    background-color: $primary-half-alpha;
    &-selected {
      background-color: $primary-main;
    }
  }

}
